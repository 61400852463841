import React from "react";
import { Link } from "gatsby";
import { IoIosContact, IoIosCheckbox, IoIosCog } from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PageHeader, CallToAction } from "../components/site/";

import TimeEntryVideo from "../images/timesheets/TimeEntry_Preview.mp4";

const TimePortal = (props) => (
  <Layout>
    <Seo
      title="Employee Access | Timesheets, Leave, Expenses & Payslips | PayHero"
      description="Payroll with employee access. Give your employees confidence that their pay and leave is accurate by making it as transparent as possible. Get started today."
      pathname={props.location.pathname}
    />
    <PageHeader
      feature="Employee Self Service"
      title={[
        <span key={0} className="-fontPrimary">
          Payroll made easy
        </span>,
        " with employee access",
      ]}
      description="Take a break from employee admin by giving your employees controlled access to submit timesheets & leave requests, claim expenses and view pay history."
      imagePath="PageHeader_Portal.png"
    />
    <Container>
      <Wrapper>
        <Row stackGap={40} className="-textCenter">
          <Box size={33}>
            <h3>
              <IoIosContact />
              <br />
              Fewer questions. Less paperwork.
            </h3>
            <p>
              Employee self service gives your team access to the{" "}
              <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link> and leave info they need
              anytime on any device.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosCheckbox />
              <br />
              Payroll your employees can trust
            </h3>
            <p>
              Give your employees confidence that their pay and leave is
              accurate by making it as transparent as possible.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosCog />
              <br />
              You’re in control
            </h3>
            <p>
              Employee self-service access is optional, and you can choose
              whether to turn on the timesheet and expenses modules.
            </p>
          </Box>
        </Row>
        <hr />
        <h2 className="-textCenter -fontCallout">
          Let your employees manage their info in PayHero, so you don’t need to.
        </h2>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <h2>Custom Onboarding & Workflows</h2>
            <h4>
              Invite new employees to submit details and complete tasks in an 
              onboarding workflow, or send company-wide surveys and policy updates. 
              Monitor completion rates and report on results to stay in the know.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Workflow Menu and Employee Onboarding | PayHero"
              filename="Workflows.png"
              rounded
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <video
              autoPlay
              loop
              muted
              playsInline
              css={{ maxWidth: "661px", width: "100%" }}
            >
              <source src={TimeEntryVideo} type="video/mp4" />
            </video>
          </Box>
          <Box size={50}>
            <h2>Intuitive Time Entry</h2>
            <h4>
              It’s easier than ever for your team to{" "}
              <Link to={process.env.GATSBY_SITE_URL + '/time'}>record online timesheets</Link>. Employees
              can submit their hours by clicking and dragging in the browser, or
              use their phone to enter start, end and break times, or start a
              clock with GPS location stamping.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50}>
            <h2>Transparent Leave Calculations</h2>
            <h4>
              The Holidays Act is complex and confusing. PayHero follows the
              very latest MBIE Holidays Act guidelines to give you peace of
              mind. Just as importantly, we give your employees the numbers they
              need to understand how their leave and holiday payments have been
              calculated, so they can trust the entitlements and deductions in
              their pay.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Employee Leave Balances | PayHero"
              filename="Portal_LeaveBalances.png"
              rounded
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Employee Payslips | PayHero"
              filename="Portal_Payslips.png"
              addShadow
            />
          </Box>
          <Box size={50}>
            <h2>Payslip History</h2>
            <h4>
              Let your employees access historical pays and see a detailed
              breakdown of the earnings and deductions for each pay period. Give
              them anytime access, so you don’t need to dig out old payslip
              records for them.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50}>
            <h2>Easy Leave Requests</h2>
            <h4>
              Employees can quickly and easily request leave and see all their
              relevant balances. A calendar view gives them a visual overview of
              leave taken throughout the year.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Employee Leave Requests | PayHero"
              filename="Portal_LeaveRequests.png"
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Employee Expenses | PayHero"
              filename="PayHero_EmployeeExpenses.png"
              addShadow
            />
          </Box>
          <Box size={50}>
            <h2>Claim expenses in real time</h2>
            <h4>
              No need for your staff to hoard their receipts in shoe boxes
              anymore. They can simply take a photo of the receipt on their
              phone, complete the details and submit the expense claim as they
              go about their business.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50}>
            <h2>Manage admin tasks from a phone</h2>
            <h4>
              Perfect for employees that don’t spend their days sitting at a
              desk, the <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>employee mobile app</Link> gives
              staff the freedom to manage leave, time and pay in the palm of
              their hands.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Record Timesheets, Request Leave, Submit Expenses & View Payslips | Employee Mobile App | PayHero"
              filename="PayHero_EmpMobile.png"
              maxWidth={500}
              centerImage
              addShadow
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Manage Employee Time | PayHero"
              filename="ManageTime_PayHero.png"
              addShadow
            />
          </Box>
          <Box size={50}>
            <h2>Delegate team management</h2>
            <h4>
              Let your managers look after their teams. With PayHero Teams
              employee leave requests and expense claims will be emailed to
              their line manager for approval. Managers can also record and edit
              staff timesheets, or even clock staff in and out on their phone as
              they arrive onsite.
            </h4>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default TimePortal;
